import { Button, Grid, Spacer, Text } from "@nextui-org/react"
import { Caption2 } from "components/shared/styled"

const Pricing = [
  {
    "plan": "Clarity",
    "description": "for one-off specific questions",
    "number_of_calls": 1,
    "call_length_minutes": 15,
    "price_total_inr": 1995,
    "price_per_candid_call_inr": 1995,
    "discount_percent": 0,
    "variant": "bordered",
    "color": "#ff77d4",
    "link": "https://buy.stripe.com/28o7tb3C495u10c3cf"
  },
  {
    "plan": "Progress",
    "description": "when you've narrowed down your options but need some validation or guidance",
    "number_of_calls": 3,
    "call_length_minutes": 15,
    "price_total_inr": 5247,
    "price_per_candid_call_inr": 1749,
    "discount_percent": 12.5,
    "variant": "shadow",
    "color": "#ff994e", 
    "link": "https://buy.stripe.com/dR6bJr6Og5TibEQ8wy"
  },
  {
    "plan": "Explorer",
    "description": "when the world is your oyster and you're ready to explore multiple routes",
    "number_of_calls": 5,
    "call_length_minutes": 15,
    "price_total_inr": 7495,
    "price_per_candid_call_inr": 1499,
    "discount_percent": 25,
    "variant": "bordered",
    "color": "#05e386",
    "link": "https://buy.stripe.com/00g4gZ4G8bdCaAM5kl"
  }
]
const PricingCards = () => {
  const onClickStripe = (link: string) => {
    window.open(link, "_blank")
  }

  return (
    <Grid.Container gap={1}>
      {Pricing.map(option => <Grid md={4} sm={4} xs={12} direction="column" css={{ textAlign: "left" }}>
          <div style={{ border: "1px solid #333", padding: 10,borderRadius: 10}}>
        <Text h3>
          {option.plan}
        </Text>
        <Text css={{ color: option.color, height: 30, "@xsMax": { h: "auto" } }} weight={"bold"}>
          {option.discount_percent > 0 ? `${option.discount_percent}% Cheaper` : ' '}
        </Text>
        <Text h1 css={{ position: "relative", lineHeight: 1, mb: "$3" }} weight="medium">
          <span style={{ fontSize: 26, position: "relative", top: "-0.525em" }}>₹</span>{option.price_per_candid_call_inr.toLocaleString()}
        </Text>
        <Text weight={"normal"} css={{letterSpacing: -0.2}}>
          {option.number_of_calls} call{option.number_of_calls > 1 ? 's' : ''} package<br/>for total ₹{option.price_total_inr.toLocaleString()}
        </Text>

        <Spacer y={1} />

        <Text css={{ fontSize: 14, height: 100, letterSpacing: 0.1, lineHeight: "22px", opacity: 0.6, "@smMax": { height: "auto" } }} weight={"medium"}>
          {option.description}
        </Text>
        <Spacer y={1} />
        <div>
          <Button
            onClick={onClickStripe.bind(this,option.link)}
            auto css={{ bgColor: option.color, color: "black" }}>
            Get Started
          </Button>

        </div>
        </div>
      </Grid>)}
    </Grid.Container>
  )

}
const NurseLeadSuccessScreen = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
      <Spacer y={2} />
      <Text h3>
        Thank you for submitting your details!
      </Text>
      <br />
      <Caption2>
        You’re one step closer on your international nursing journey. Next, select the call package below that suits you so we can custom match you with one or multiple experts in your destination country and chosen specialism
      </Caption2>
      <Spacer y={2} />
      <PricingCards />
      <Spacer y={1} />
      <Spacer y={1} />
    </div>
  )
}

export default NurseLeadSuccessScreen;