import { Button, Link, Navbar } from "@nextui-org/react";
import { useHistory } from "react-router-dom";

const items = [
  {
    id: "howItWorks",
    display: "How it works",
    divId: "howItWorks"
  },
  {
    id: "countryFacts",
    display: "Country facts",
    divId: "countryFacts",
  },
  {
    id: "faq",
    display: "FAQ",
    divId: "faq",
  },
  {
    id: "contact",
    display: "Contact",
    path: "/contact",
  }
];

const LandingNavBar = () => {
  const history = useHistory();

  const scrollToDiv = (id: string) => {
    const section = document.getElementById(id);
  
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', });
    }
  }

  const scrollToMobileDiv = (id: string) => {
    const element = document.querySelector('[aria-label="toggle navigation"]');
    if (element) {
      (element as any).click();
    }
    setTimeout(() => {
      scrollToDiv(id)
    }, 300); 
  };

  return (
    <Navbar 
      disableShadow 
      disableBlur 
      css={{ bgColor: "#fff497" }} 
      containerCss={{ bgColor: "#fff497" }}>
      <Navbar.Brand onClick={() => history.push("/")}>
        <img
          width={150}
          style={{ objectFit: "contain", cursor: "pointer" }}
          src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Candid-Logo-Black.png?alt=media&token=a7e0a7ee-6f9c-4383-a870-3540e832d101" />
      </Navbar.Brand>
      <Navbar.Toggle aria-label="toggle navigation" showIn={"xs"} />
      <Navbar.Collapse disableBlur css={{ bgColor: "#fff497" }}>
        {items.map((item) => (
          <Navbar.CollapseItem key={item.id}>
            <Link
              onClick={() => item.divId ? scrollToMobileDiv(item.divId) : history.push(item.path!)}
              color="inherit"
              css={{ minWidth: "100%" }}>
              {item.display}
            </Link>
          </Navbar.CollapseItem>
        ))}
      </Navbar.Collapse>

      <Navbar.Content hideIn="xs">
        {items.map(item => 
          <Navbar.Link 
            key={item.id}
            onClick={() => item.divId ? scrollToDiv(item.divId) : history.push(item.path!)}>
            {item.display}
          </Navbar.Link>
        )}
        <Navbar.Item>
          <Button
            auto
            as={Link}
            rounded
            bordered
            color="secondary"
            onPress={() => history.push("/register")}>
            Sign up
          </Button>
        </Navbar.Item>
      </Navbar.Content>
    </Navbar>
  )
}

export default LandingNavBar;