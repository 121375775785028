import { Container, Grid, Text } from "@nextui-org/react";
import { styles } from "./styles";
import Canvas from "components/shared/stripe-gradient/canvas";
import { useHistory } from "react-router-dom";
import LandingNavBar from "components/landing/navbar";

interface AuthLayoutProps {
  title?: string;
  description?: string;
  reverse?: boolean;
  children: JSX.Element | JSX.Element[]
}
const AuthLayout = ({
  title,
  description,
  reverse = true,
  children
}: AuthLayoutProps) => {
  const history = useHistory();

  return (
    <Grid.Container css={{ h: "100vh", "@smMax": { h: "auto" } }} direction={reverse ? "row-reverse" : "row"} id="authContainer">
      <Grid md={6} sm={6} xs={12} css={styles.heroContainer}>
        {/* <Canvas/> */}
        <div style={{zIndex: 9999, justifyContent:"space-between", display:"flex", flexDirection:"column"}}>
        <img
          onClick={() => history.push('/')}
          width={200}
          style={{ objectFit: "contain", cursor:"pointer" }}
          src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Candid-Logo-Black.png?alt=media&token=a7e0a7ee-6f9c-4383-a870-3540e832d101" />
        <div>
          <Text h1 css={styles.heroText} weight={"bold"} hideIn={"sm"}>
            {title ?? "Land your dream nursing job in your preferred destination country"}
          </Text>
          {description && <Text css={{ whiteSpace: "pre-wrap",letterSpacing: 0.1, fontSize: 20 }} hideIn={"sm"}>
            {description}
          </Text>}
          </div>
        <Text small hideIn={"sm"}>
          © 2024 Agency Diagonal Limited. All rights reserved.
        </Text>
        </div>

      </Grid>
      <Grid md={6} sm={6} xs={12} justify="center" alignItems="center" css={{ p: '$3' }} direction="column">
        <Container xs css={styles.contentContainer}>
          {children}
        </Container>
      </Grid>
    </Grid.Container>
  );
};

export default AuthLayout;