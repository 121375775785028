import { Button, Text } from "@nextui-org/react"
import { useFormikContext } from "formik";

interface Option {
  display: string;
  value: string;
}

interface SelectOptionInputProps {
  id: string;
  selectionMode?: "single" | "multiple";
  maxSelections?: number;
  options: Option[];
}

const SelectOptionInput = ({
  id,
  options,
  selectionMode = "multiple",
  maxSelections = Infinity
}: SelectOptionInputProps) => {

  const { values, errors, touched, setFieldValue } = useFormikContext<any>();

  const onChangeOption = (option: Option) => {
    if(values?.readOnly) return;
    const { value } = option;
    if (selectionMode === "single") {
      setFieldValue(id, value);
      return;
    }

    const currValues = values[id] as string[];
    const isSelected = currValues.some(t => t === value);

    if (isSelected) {
      // Deselect the option
      setFieldValue(id, currValues.filter(t => t !== value));
    } else {
      // Check if the limit is exceeded
      if (currValues.length < maxSelections) {
        // Select the option
        setFieldValue(id, [...currValues, value]);
      } else {
        // Optionally, you can provide feedback when the limit is reached
        console.warn(`You can select up to ${maxSelections} options only.`);
      }
    }
  }

  return (
    <>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {options.map(option => (
        <Button
          size="md"
          auto
          css={{ m: "$2" }}
          key={option.value} // Don't forget to add a unique key to the elements in a loop
          bordered={selectionMode === "single" ? values[id] !== option.value : values[id]?.every((val: string) => val !== option.value)} // Fixed the issue here
          onClick={onChangeOption.bind(this, option)}>
          {option.display}
        </Button>
      ))}
    </div>
      <Text small color="error" css={{mt:"$5"}}>
        {touched[id] && errors[id]}
      </Text>
    </>
  );
};

export default SelectOptionInput;