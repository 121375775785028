import Typewriter from "typewriter-effect";
import { Text } from "@nextui-org/react";
import { HEROTEXT_COMPANIES } from "api/data/landing";
import { styles } from "./styles";
import { useMediaQuery } from "hooks/useMediaQuery";

const OPTIONS = {
  loop: true,
  cursorClassName: "typewrite-cursor",
  wrapperClassName: "typewriter-root"
}

const COUNTRIES = [
  "UK",
  "Canada",
  "USA",
  "Australia",
  "New Zealand",
  "UAE",
  "Singapore"
]
const HeroText = () => {
  const isSmall = useMediaQuery(600);

  return (
    <Text  h1 weight="extrabold" css={styles.heroText}>
      {`Land your dream nursing job in \n`}
      <Typewriter
        options={OPTIONS}
        onInit={(typewriter) => {
          COUNTRIES.forEach(company => {
            typewriter
              .typeString(company)
              .pauseFor(1000)
              .deleteAll()
          })
          typewriter.start();
        }}/>
    </Text>
  )
}

export default HeroText;