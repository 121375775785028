import { Button, Input, Loading, Spacer, Text, Textarea } from "@nextui-org/react"
import { Caption } from "components/shared/styled";
import { CONTACT_SUPPORT_VALIDATION_SCHEMA } from "config/validation.schema";
import { Formik, FormikHelpers } from "formik";
import { useAPI } from "hooks/useApi";
import { useSnackbar } from "notistack";
import * as qs from "query-string";

interface ContactFormValues {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactSupportForm = () => {
  const api = useAPI();
  const snackbar = useSnackbar();

  const {
    name,
    email,
    message
  } = qs.parse(window.location.search);

  const onSubmit = async (values: ContactFormValues, { setSubmitting, resetForm }: FormikHelpers<ContactFormValues>) => {
    setSubmitting(true);
    await api.createSupportTicket(values);
    setSubmitting(false);
    resetForm();
    snackbar.enqueueSnackbar('Your inquiry has been successfully sent, someone will be in touch shortly', { variant: 'success' });
  }

  return (
    <Formik
      initialValues={{
        name: name as string ?? "",
        phone: "",
        email: email as string ?? "",
        message: message as string ?? "",
      }}
      validationSchema={CONTACT_SUPPORT_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        isValid,
        errors,
        values,
        touched,
        handleChange,
        handleSubmit
      }) => (
        <div style={{ width: "100%" }}>
          <Spacer y={0.5} />
          <Text h2 weight="medium">
            How can we help?
          </Text>
          <Caption css={{ color: "$gray700" }}>
            We're here to help. Please fill in our contact form below, or you can also email us directly at hello@candidexpert.ai.          </Caption>
          <Spacer y={1} />
          <Input
            fullWidth
            id="name"
            name="name"
            placeholder="enter your name"
            onChange={handleChange}
            value={values.name}
            helperText={touched?.name && errors?.name ? errors.name : ""}
            helperColor={"error"} />
          <Spacer y={0.5} />
          <Input
            fullWidth
            id="phone"
            name="phone"
            placeholder="enter your phone"
            onChange={handleChange}
            value={values.phone}
            helperText={touched?.phone && errors?.phone ? errors.phone : ""}
            helperColor={"error"} />
          <Spacer y={0.5} />
          <Input
            fullWidth
            id="email"
            name="email"
            placeholder="enter your email"
            onChange={handleChange}
            value={values.email}
            helperText={touched?.email && errors?.email ? errors.email : ""}
            helperColor={"error"} />
          <Spacer y={0.5} />
          <Textarea
            fullWidth
            id="message"
            name="message"
            placeholder="enter your message"
            onChange={handleChange}
            value={values.message}
            helperText={touched?.message && errors?.message ? errors.message : ""}
            helperColor={"error"} />
          <Spacer y={0.5} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              auto
              rounded
              disabled={isSubmitting || !isValid}
              onClick={() => handleSubmit()}>
              {isSubmitting ? <Loading type="spinner" /> : "Submit"}
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default ContactSupportForm;