import { Container, Navbar } from "@nextui-org/react";
import ContactSupportForm from "components/forms/contact-support"
import { Fragment } from "react";
import { useHistory } from "react-router-dom";

const ContactPage = () => {
  const history = useHistory();

  return (
    <Fragment>
      <Navbar variant={"static"} disableShadow>
        <Navbar.Brand onClick={() => history.push('/')} css={{cursor:"pointer"}}>
          <img
            style={{ width: 150 }}
            src={process.env.REACT_APP_LOGO_URL} />
        </Navbar.Brand>
      </Navbar>
      <Container xs justify="center" alignItems="center" css={{ height: "90vh" }} display="flex">
        <ContactSupportForm />
      </Container>
    </Fragment>
  )
}

export default ContactPage;