import { Textarea } from "@nextui-org/react";
import { useFormikContext } from "formik";

const FormikTextAreaInput = ({ id }: { id: string }) => {
  const { handleChange, values, touched, errors } = useFormikContext<any>();
  return (
    <Textarea
      id={id}
      name={id}
      onChange={handleChange}
      value={values[id]}
      color="primary"
      fullWidth
      minRows={5} 
      helperText={touched[id] && (errors as any)[id] ? (errors as any)[id] : ""}
      helperColor={"error"}/>
  )
}

export default FormikTextAreaInput;