import { Divider, Link, Spacer, Text } from "@nextui-org/react"
import { useHistory } from "react-router-dom"

const LandingFooter = () => {
  const history = useHistory();

  return (
    <>
      <Divider />
      <Spacer y={1} />
      <div style={{ textAlign: "center", }}>
        <Text>© Agency Diagonal Limited. All Rights Reserved.</Text>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Link css={{ pt: "$5", color:"black" }} onClick={() => window.open("mailto:hello@candidexpert.ai")}>
            Contact Us
          </Link>
        </div>
      </div>
      <Spacer y={1} />
    </>
  )
}

export default LandingFooter;