

import * as Yup from "yup";

const CONTACT_SUPPORT_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  message: Yup.string().required("Please enter your message"),
});


const NURSE_LEAD_VALIDATION_SCHEMA = Yup.object({
  fname: Yup.string().required("Please enter a valid first name"),
  lname: Yup.string().required("Please enter a valid surname"),
  email: Yup.string().email().required("Please enter a valid email"),
  phone: Yup.string().required("Please enter a valid phone"),
  nationality: Yup.string().required("Please enter a valid nationality"),
  currentCity: Yup.string().required("Please enter a valid city"),
  currentStatus: Yup.string().required("Please enter your current status"),
  highestQualification: Yup.string().required("Please enter your highest qualification"),
  seniorityLevel: Yup.string().required("Please enter your level of seniority"),
  yearsOfExperience: Yup.string().required("Please enter your number of years of experience"),
  destinationCountry: Yup.array().of(Yup.string().required()).min(1, "Please select one or more destination countries"),
  preferredSpecialism: Yup.array().of(Yup.string().required()).min(1,"Please enter your current (or preferred) specialisms"),
  callGoals: Yup.array().of(Yup.string().required()).min(1, "Please select one or more call goals"),
  additionalInformation: Yup.string().optional(),
  daysAvailable: Yup.array().of(Yup.string().required()).min(1, "Please select one or more days"),
  timesAvailable: Yup.array().of(Yup.string().required()).min(1, "Please select one or more times")
});

export {
  NURSE_LEAD_VALIDATION_SCHEMA,
  CONTACT_SUPPORT_VALIDATION_SCHEMA
};