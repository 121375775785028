import { Text } from "@nextui-org/react";
import ReferralInput from "components/forms/fields/referral-input";
import SelectOptionInput from "components/forms/fields/select-option-input";
import FormikTextAreaInput from "components/forms/fields/text-area-input";
import NurseDetailsForm from "components/forms/nurse-details";

export const STATUS_OPTIONS = [
  "Just curious",
  "I'm studying",
  "Preparing for English language tests",
  "Preparing for a Nursing interview",
  "I have an international offer",
  "I have been unsuccessful in securing an offer"
];

export const DESTINATION_COUNTRY = [
  "Australia",
  "Canada",
  "India",
  "New Zealand",
  "South Korea",
  "Singapore",
  "Philippines",
  "Republic of Ireland",
  "South Africa",
  "United Arab Emirates",
  "United Kingdom ",
  "United States",
];

export const SPECIALISM = [
  "Critical Care (ICU)",
  "Accident & Emergency",
  "Paediatric",
  "Oncology",
  "Cardiac",
  "Neonatal",
  "Psychiatric/Mental Health",
  "Geriatric/Elderly Care",
  "Surgical/Operating Room",
  "Anesthetist",
  "Public Health",
  "Family Nurse Practitioner",
  "Orthopaedic",
  "Diabetes",
  "Hospice and Palliative Care",
  "Rehabilitation",
  "Infectious Disease",
  "Midwifery",
  "Occupational Health",
  "General",
  "Other"
]
export const CALLGOALS = [
  "Application process",
  "Language proficiency",
  "Licensing & Registration",
  "Visas & Immigration",
  "Pay & Overtime",
  "Professional Development & Promotion",
  "International Standards in my specialism",
  "Workplace culture",
  "Living expenses",
  "Safety, Support & Wellbeing",
  "Other"
];

export const HIGHEST_QUALIFICATION = [
  "Currently Studying",
  "Nursing Diploma",
  "Nursing Degree",
  "Nursing Postgraduate"
]

export const SENIORITY_LEVEL = [
  "Nursing Assistant",
  "Licensed Practical Nurse",
  "Registered Nurse",
  "Advanced Registered Practice Nurse",
  "Nursing Manager",
  "Director of Nursing",
  "Other"
];

export const YEARS_OF_EXPERIENCE = [
  "<1",
  "1-2",
  "3-5",
  "6-10",
  "10+"
]

export const DAYS = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun"
];

export const TIMES = [
  "Morning",
  "Afternoon",
  "Evening",
  "Night",
];

export const STEPS = [
  {
    title: "Let's get you connected",
    component: <NurseDetailsForm />,
    validateFields: ["name", "email", "phone", "nationality", "currentCity"]
  },
  {
    title: "What's your current status?",
    component: <SelectOptionInput id="currentStatus" selectionMode="single" options={STATUS_OPTIONS.map(o => ({ value: o, display: o }))} />,
    validateFields: ["currentStatus"]
  },
  {
    title: "What's your highest qualification?",
    component: <SelectOptionInput id="highestQualification" selectionMode="single" options={HIGHEST_QUALIFICATION.map(o => ({ value: o, display: o }))} />,
    validateFields: ["highestQualification"]
  },
  {
    title: "What's your level of seniority?",
    component: <SelectOptionInput id="seniorityLevel" selectionMode="single" options={SENIORITY_LEVEL.map(o => ({ value: o, display: o }))} />,
    validateFields: ["seniorityLevel"]
  },
  {
    title: "How many years of experience do you have?",
    component: <SelectOptionInput id="yearsOfExperience" selectionMode="single" options={YEARS_OF_EXPERIENCE.map(o => ({ value: o, display: o }))} />,
    validateFields: ["yearsOfExperience"]
  },
  {
    title: "What's your preferred destination country?",
    description: "Select upto 3",
    component: <SelectOptionInput id="destinationCountry" maxSelections={3} selectionMode="multiple" options={DESTINATION_COUNTRY.map(o => ({ value: o, display: o }))} />,
    validateFields: ["destinationCountry"]
  },
  {
    title: "What's your current (or preferred) specialism",
    description: "Select upto 3",
    component: <SelectOptionInput id="preferredSpecialism" maxSelections={3} selectionMode="multiple" options={SPECIALISM.map(o => ({ value: o, display: o }))} />,
    validateFields: ["preferredSpecialism"]
  },
  {
    title: "What's your learning goal from the call?",
    description: "Select upto 2",
    component: <SelectOptionInput id="callGoals" maxSelections={2} selectionMode="multiple" options={CALLGOALS.map(o => ({ value: o, display: o }))} />,
    validateFields: ["callGoals"]
  },
  {
    title: "Anything else you would like an opinion on during the call?",
    component: <FormikTextAreaInput id="additionalInformation" />
  },
  {
    title: "When can you take a call?",
    component: (
      <>
        <Text>Days</Text>
        <SelectOptionInput
          selectionMode="multiple"
          id="daysAvailable"
          options={DAYS.map(o => ({ value: o, display: o }))} />
        <Text>Times</Text>
        <SelectOptionInput
          selectionMode="multiple"
          id="timesAvailable"
          options={TIMES.map(o => ({ value: o, display: o }))} />
      </>
    ),
    validateFields: ["timesAvailable", "daysAvailble"]
  },
  {
    title: "Help others land their dream job too",
    description: "Refer upto 3 people to get premium access to new features, access the best experts and discounts on pricing",
    component: <ReferralInput />,
    validateFields: []
  }
]


