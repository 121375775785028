import "./App.css";
import "./api";
import "scroll-behavior-polyfill";

import { Views } from "./screens/";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
import { NextUIProvider, createTheme } from "@nextui-org/react";
import { BrowserRouter as Router } from "react-router-dom";
import { APIProvider } from "contexts/api";
import { IntercomProvider } from 'react-use-intercom';

const INTERCOM_APP_ID = 'gmhnb6nu';

const nextUITheme = createTheme({
  type: "light",
  theme: {
    colors: {
      link: "#673AB7",
      primary: "#000",
      primaryLight: "#bfefda",
      primaryLightContrast: "#108944",
      secondary: "#000",
      gray700: "#898989",
      gradient: "linear-gradient(to right, #000000, #000000)",
    },
    fonts: {
      sans: "Anybody",
      mono: "Anybody"
    }
  }
});

function App() {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>

      <CookiesProvider>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <NextUIProvider theme={nextUITheme}>
            <Router>
              <APIProvider>
                <Views />
              </APIProvider>
            </Router>
          </NextUIProvider>
        </SnackbarProvider>
      </CookiesProvider>
    </IntercomProvider>
  );
}

export default App;
