import { Button, Grid, Loading, Spacer } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { BsArrowRight } from "react-icons/bs";
import FormikFieldsErrorText from "../formik-error-field-text";

const FormStepperActions = ({ step, setStep, STEPS, handleSaveDraft, shouldValidate, disableSubmission = false }: any) => {
  const { handleSubmit, isSubmitting, isValid, setFieldValue, values, setSubmitting, validateForm, errors, validateField, setTouched, setFieldTouched } = useFormikContext<any>();
  const hasBack = STEPS[step - 1] != null;
  const hasNext = STEPS[step + 1] != null;

  return (
    <>
      {!hasNext && <FormikFieldsErrorText />}
      <Spacer y={1} />
      <Grid xl={12} justify="space-between" css={{ display: "flex !important", alignItems: "center" }}>
        {hasBack ? (
          <>
            <Button
              auto
              rounded
              css={{ bgColor: "$gray100", color: "#000" }}
              onClick={() => setStep(step - 1)}>
              Previous Step
            </Button>
            <Spacer x={0.5} />
          </>
        ) : <div></div>}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <Button
            auto
            light
            disabled={disableSubmission}
            rounded
            onClick={async () => {
              if (shouldValidate) {
                const fields = STEPS[step].validateFields || [];

                await Promise.all(fields.map((field: string) => setFieldTouched(field)));
                const errors = await validateForm(values);
                if (fields.some((field: string) => Object.keys(errors).some(f => f === field))) {
                  return;
                }
              }
              handleSaveDraft(values, { setSubmitting, validateForm, setFieldValue })
            }}>
            Save as Draft
          </Button> */}
          {hasNext ? (
            <Button
              auto
              rounded
              size="lg"
              iconRight={<BsArrowRight />}
              onClick={async () => {
                if (shouldValidate) {
                  const fields = STEPS[step].validateFields || [];

                  await Promise.all(fields.map((field: string) => setFieldTouched(field)));
                  const errors = await validateForm(values);
                  if (fields.some((field: string) => Object.keys(errors).some(f => f === field))) {
                    console.log("HAS ERRORS")
                    return;
                  }
                }
                setStep(step + 1)
              }}>
              Next
            </Button>
          ) : (
            <Button
              auto
              rounded
              disabled={disableSubmission || isSubmitting || !isValid}
              onClick={async () => {
                await setFieldValue("type", "COMPLETE")
                handleSubmit()
              }}>
              {isSubmitting ? <Loading type="spinner" /> : "Submit"}
            </Button>
          )}
        </div>
      </Grid>
    </>
  )

}

export default FormStepperActions;