import { Input, Spacer } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { DEFAULT_INPUT_PROPS } from "types/forms";

const ReferralInput = () => {
  const { setFieldValue } = useFormikContext<any>();

  const [referrals, setReferrals] = useState({
    referralOne: "",
    referralTwo: "",
    referralThree: ""
  });

  useEffect(() => {
    setFieldValue("referrals", `${referrals.referralOne},${referrals.referralTwo},${referrals.referralThree}`)
  }, [referrals]);

  return (
    <>
      <Input
        {...DEFAULT_INPUT_PROPS}
        label="Referral 1"
        placeholder="enter email address"
        onChange={(e) => setReferrals(prevState => ({ ...prevState, referralOne: e.target.value }))}
        value={referrals.referralOne} />
      <Spacer y={0.5} />
      <Input
        {...DEFAULT_INPUT_PROPS}
        label="Referral 2"
        onChange={(e) => setReferrals(prevState => ({ ...prevState, referralTwo: e.target.value }))}
        value={referrals.referralTwo} />
      <Spacer y={0.5} />
      <Input
        {...DEFAULT_INPUT_PROPS}
        label="Referral 3"
        onChange={(e) => setReferrals(prevState => ({ ...prevState, referralThree: e.target.value }))}
        value={referrals.referralThree} />
    </>
  )
}

export default ReferralInput;