import axios from "axios";
import { useState } from "react";
import { FormikHelpers } from "formik"
import { useSnackbar } from "notistack";

import { NURSE_LEAD_VALIDATION_SCHEMA } from "config/validation.schema";
import { STEPS } from "./steps";
import { CreateNurseLeadForm } from "types/forms";
import BaseOnboardingForm from "../base-onboarding-form";
import AuthLayout from "layout/auth-layout";
import NurseLeadSuccessScreen from "../success";

const baseId = 'appwP8jwDEscCvtJg';
const tableName = 'tblH0y1MkZpK2dgQh';
const apiKey = 'patjNqpNWVlVE16NW.b4c2e7a610960906e8f99fb461372ee653265444971f683b68af076d621d5635';
const url = `https://api.airtable.com/v0/${baseId}/${tableName}`;
const headers = {
  'headers': {
    "Authorization": `Bearer ${apiKey}`
  }
};

const NurseLeadForm = () => {
  const snackbar = useSnackbar();

  const [initialValues] = useState<CreateNurseLeadForm>({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    daysAvailable: [],
    timesAvailable: [],
    callGoals: [],
    destinationCountry: [],
    preferredSpecialism: [],
    additionalInformation: "",
    referrals: ""
  } as any);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(0);
  const [recordId, setRecordId] = useState();

  const onSubmit = async (
    values: CreateNurseLeadForm,
    { setSubmitting }: FormikHelpers<CreateNurseLeadForm>) => {
    setSubmitting(true);
    const data = {
      fields: {
        "Name": `${values.fname} ${values.lname}`,
        "Email": values.email,
        "Phone": values.phone,
        "Nationality": values.nationality,
        "Current City": values.currentCity,
        "Current Status": values.currentStatus,
        "Highest Qualification": values.highestQualification,
        "Level of Seniority": values.seniorityLevel,
        "Years of Experience": values.yearsOfExperience,
        "Destination Country": values.destinationCountry.join(','),
        "Current Specialism": values.currentSpecialism,
        "Preferred Specialism": values.preferredSpecialism.join(','),
        "Call Goals": values.callGoals.join(','),
        "Time of Day": values.timesAvailable.join(','),
        "Day": values.daysAvailable.join(','),
        "Additional Information": values.additionalInformation,
        "Referrals": values.referrals
      }
    };

    try {
      
      if(recordId) {
        await axios.put(`${url}/${recordId}`, data, headers);
      } else {
        const response = await axios.post(`${url}`, data, headers);
        setRecordId(response.data?.id);
      }

      if (step === STEPS.length - 1) {
        snackbar.enqueueSnackbar(`Success!`, { variant: "success" });
        setSuccess(true);
      }
    } catch (err) {
      snackbar.enqueueSnackbar("An error occurred, please try again later", { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <AuthLayout reverse={false} title={success ? "You're almost there!" : STEPS[step].title} description={success ? undefined : STEPS[step].description}>
      {success ? <NurseLeadSuccessScreen /> :
        <BaseOnboardingForm
          onStepChange={(step, isBack, values, setSubmitting) => {
            if(step > 0 && step !== STEPS.length -1 && !isBack) {
              onSubmit(values, { setSubmitting } as any);
            }
            setStep(step)
          }}
          steps={STEPS}
          onSubmit={onSubmit}
          refreshData={() => console.log("REFRESH")}
          loading={false}
          validationSchema={NURSE_LEAD_VALIDATION_SCHEMA}
          initialValues={initialValues} />}
    </AuthLayout>
  )
}


export default NurseLeadForm;