import { CALLGOALS, DESTINATION_COUNTRY, HIGHEST_QUALIFICATION, SENIORITY_LEVEL, SPECIALISM, STATUS_OPTIONS, YEARS_OF_EXPERIENCE } from "screens/onboarding/nurses/steps";

export const DEFAULT_INPUT_PROPS = {
  clearable: true,
  fullWidth: true,
  size: 'lg',
} as any;

export interface CreateNurseLeadForm {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  nationality: string;
  currentCity: string;
  daysAvailable: string[];
  timesAvailable: string[];
  highestQualification: typeof HIGHEST_QUALIFICATION;
  seniorityLevel: typeof SENIORITY_LEVEL;
  yearsOfExperience: typeof YEARS_OF_EXPERIENCE;
  currentStatus: typeof STATUS_OPTIONS;
  destinationCountry: typeof DESTINATION_COUNTRY;
  currentSpecialism: typeof SPECIALISM;
  preferredSpecialism: typeof SPECIALISM;
  callGoals: typeof CALLGOALS[];
  referrals: string;
  additionalInformation: string;
}