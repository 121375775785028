import { CSS } from "@nextui-org/react";

export const styles: {[key: string]: CSS} = {
  heroText: { 
    fontSize: "$7xl", 
    lineHeight: 1.14, 
    whiteSpace: "pre-wrap",
    "@lgMax": {
      fontSize: 72
    },
    "@mdMax": {
      fontSize: 60
    },
    "@smMax": {
      fontSize: 52
    },
    "@xsMax": {
      fontSize: 42
    },
  }
}