import { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Loading } from "./loading";
import LandingPage from "./landing";
import PrivacyPolicy from "./privacy";

import ContactPage from "./contact";
import NurseLeadForm from "./onboarding/nurses";

export const Views = () => {

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={'/'} component={LandingPage} />
        <Route exact path={'/register'} component={NurseLeadForm}/>
        <Route exact path={'/privacy'} component={PrivacyPolicy}/>
        <Route exact path={'/contact'} component={ContactPage}/>
      </Switch>
    </Suspense>
  );
};

export default Views;
