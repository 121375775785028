import { Card, Grid, Spacer, Text } from "@nextui-org/react"
import { BiGlobe } from "react-icons/bi";
import { MdCurrencyExchange } from "react-icons/md";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { CountryNursingData } from "api/data/landing_nurse";
import { PiCertificate } from "react-icons/pi";
import { HiLanguage } from "react-icons/hi2";
import { BsCalendarDate } from "react-icons/bs";
import { RiNurseLine } from "react-icons/ri";
import { BsCalendarWeek } from "react-icons/bs";
import { MdOutlinePercent } from "react-icons/md";
import { BsCurrencyExchange } from "react-icons/bs";

interface CountryStatisticsProps {
  country: CountryNursingData;
}

const CountryStatistics = ({
  country
}: CountryStatisticsProps) => {

  const stats = [
    {
      stat: country.working_population,
      description: "Total adult working population"
    },
    {
      icon: <BsCurrencyExchange fontSize={80} color="orange" />,
      stat: country.currency,
      description: "Currency"
    },
    {
      icon: <MdCurrencyExchange fontSize={80} color="orange" />,
      stat: country.minimum_wage,
      description: "Minimum wage"
    },
    {
      icon: <HiOutlineReceiptTax fontSize={80} color="orange" />,
      stat: country.taxes,
      description: "Taxes"
    },
    {
      icon: <MdOutlinePercent fontSize={80} color="orange" />,
      stat: country.tax_free_allowance,
      description: "Tax-free allowance"
    },
    {
      stat: country.nurses,
      description: "Total nurses in the country registered and authorised to work"
    },
    {
      icon: <RiNurseLine fontSize={80} color="orange" />,
      stat: country.nurses_per_capita,
      description: "Nurses per capita (OECD)"
    },
    {
      icon: <PiCertificate fontSize={80} color="orange" />,
      stat: country.qualification,
      description: "Qualification and authority required to work as a nurse"
    },
    {
      icon: <HiLanguage fontSize={80} color="orange" />,
      stat: country.language_certification,
      description: "Language certification required"
    },
    {
      icon: <BsCalendarWeek fontSize={80} color="orange" />,
      stat: country.holidays,
      description: "Holidays and statutory days allowances"
    },
    {
      icon: <BsCalendarDate fontSize={80} color="orange" />,
      stat: `${country.maternity_paternity.maternity_leave}. ${country.maternity_paternity.paternity_leave}`,
      description: "Maternity and paternity pay/rules"
    }
  ];

  const renderCard = ({ stat, description, icon }: { stat: string; description: string; icon?: JSX.Element }) => {
    return (
      <Card variant="flat" css={{ bgColor: "#fffab5", mb: "$5", width: "100%" }} >
        <Card.Body css={{ display: "flex", flexDirection: "row", alignItems: "center", "@smMax": { flexDirection: "column", textAlign: "center" } }}>
          {icon ?? <BiGlobe fontSize={72} color="orange" />}
          <div style={{ marginLeft: 15 }}>
            <Text h2 css={{ mb: 0, lineHeight: 1.3, "@smMax": { fontSize: 24 } }}>
              {stat}
            </Text>
            <Text>
              {description}
            </Text>
          </div>
        </Card.Body>
      </Card>)
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", flex: 1, background: "#fffab5", borderRadius: 15, padding: 30 }}>
      <Text css={{ textAlign: "left", color: "Gray" }} h4>{country.name}</Text>
      <Spacer y={0.5} />

      <Text h2 b>A fun fact about nurses in {country.name}</Text>
      <Text h3>
        {country.fun_fact}
      </Text>
      <Spacer y={2} />

      <Grid.Container gap={0}>
        <Grid md={6} direction="column">
          {stats.slice(0, 5).map(stat => renderCard(stat))}
        </Grid>
        <Grid md={6} direction="column">
          {stats.slice(6, stats.length - 1).map(stat => renderCard(stat))}
        </Grid>
      </Grid.Container>

    </div>
  )
}

export default CountryStatistics;