import { CSSProperties, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Collapse, Container, Grid, Image, Spacer, Text } from "@nextui-org/react"
import { BsArrowRight } from "react-icons/bs";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import { Carousel } from '@trendyol-js/react-carousel';
import { styles } from "./styles";
import LandingFooter from "components/landing/footer";
import HeroText from "components/landing/hero-text";
import { CountryStats, Faq, HowItWorks, Logos, Quotes } from "api/data/landing_nurse";
import { useMediaQuery } from "hooks/useMediaQuery";
import { countryEmojiMap } from "utils/currencyMap";
import CountryStatistics from "components/landing/country-stats";
import LandingNavBar from "components/landing/navbar";

const LandingPage = () => {
  const history = useHistory();

  const isSmall = useMediaQuery(600);
  const isXSmall = useMediaQuery(400);

  const countryInfoRef = useRef<HTMLDivElement | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<any>();

  useEffect(() => {
    if (countryInfoRef.current) {
      countryInfoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCountry]);

  const onClickCta = () => history.push('/register');

  return (
    <>
      <LandingNavBar />
      <div style={styles.heroContainer as CSSProperties}>
        <Container lg display="flex" css={{ maxH: "fit-content", overflow: "hidden", "@xsMax": { maxH: "130vh" } }}>
          <Grid.Container>
            <Grid md={6} sm={6} direction="column" css={{ maxH: "83vh" }} >
              <Spacer y={1} />

              <Spacer y={isSmall ? 1.5 : 8} />
              <HeroText />
              <Spacer y={1} />
              <Text css={{ ...styles.heroSubtitle, textAlign: "left" }} color="primary">
                Speak directly with nursing decision-makers about culture, pay, well-being, interview prep and more.
              </Text>
              <Spacer y={isSmall ? 1 : 2} />
              <div style={{ display: "flex" }}>
                <Button
                  auto
                  rounded
                  animated
                  css={{ zIndex: 1 }}
                  size={isSmall ? "lg" : "xl"}
                  iconRight={<BsArrowRight />}
                  onClick={onClickCta}>
                  Get started for free
                </Button>
              </div>
              <Spacer y={isSmall ? 1 : 2} />
            </Grid>
            <Grid md={6} sm={6} css={{ pt: "0px", "@xsMax": { mt: -15 } }}>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                <img src="https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Doctor_Candid_Updated2.png?alt=media&token=aef70e5b-03d2-4360-9796-6a90cac21b4e" />
              </div>
            </Grid>
          </Grid.Container>
        </Container>

        <div style={{ width: "100%", background: "#fff04b" }}>
          <Container md css={{ display: "flex", p: "$8", alignItems: "center", justifyContent: "space-between", "@smMax": { flexDirection: "column", } }}>
            <Text small color="black" css={{ mb: "$5" }}>
              ACCESS LEADERS FROM:
            </Text>
            <div style={{ display: "flex", alignItems: "center", overflow: "hidden", width: "100%", justifyContent: "space-between" }}>
              {Logos.slice(0, isSmall ? 4 : Logos.length - 1).map(logo => <img
                style={{ width: isSmall ? 75 : 110, filter: "grayscale(1)", paddingRight: 15 }}
                src={logo} />
              )}</div>

          </Container>
        </div>
      </div>

      {!isSmall && <Spacer y={2} />}
      <Container xl justify="center" alignItems="center" css={{ "@xsMax": { pl: 0, pr: 0 } }}>
        {!isSmall && <Spacer y={3} />}
        <div style={{ textAlign: "center" }}>

          <Container xl css={{ "@xsMax": { bgColor: "#fffef2", paddingTop: "10%" }, pl: 0, pr: 0 }}>
            <Text h1 css={{ fontSize: 80, "@smMax": { fontSize: 42 } }}>
              Get all <span style={{ textDecoration: "underline", textDecorationColor: "black" }}>your</span> questions answered
            </Text>
            <Container md>

              <Carousel
                useArrowKeys
                swiping
                responsive
                rightArrow={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BiSolidRightArrow fontSize={isSmall ? 16 : 48} style={{ cursor: "pointer" }} />
                  </div>}
                leftArrow={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BiSolidLeftArrow fontSize={isSmall ? 16 : 48} style={{ cursor: "pointer" }} />
                  </div>}
                swipeOn={0.15}
                autoSwipe={5000}
                className="carousel"
                show={1}
                slide={1}
                transition={0.5}
              >
                {Quotes.map((quote, idx) => (
                  <Grid.Container alignItems="center" gap={5} css={{ "@smMax": { flexDirection: "column-reverse" } }}>
                    <Grid md={6} sm={6} css={{ "@smMax": { pt: 0 } }}>
                      <img src={quote.image} />
                    </Grid>
                    <Grid md={6} sm={6} direction="column" css={{ display: "flex", textAlign: "left", "@smMax": { flexDirection: "column", textAlign: "center" } }}>
                      <div className="talk-bubble tri-right round right-top" style={{ "--message-color": quote.color ?? "black", "--message-border-color": quote.color ?? "black" } as CSSProperties}>
                        <div className="talktext">
                          <Text
                            weight={"medium"}
                            css={{ fontSize: "1.5vw", lineHeight: 1.2, color: quote.color == "#f2ef02" || quote.color == "#43ffb0" ? "black" : "white", "@smMax": { fontSize: 20 } }}>
                            {quote.question}
                          </Text>
                        </div>
                      </div>
                      <div>
                        <Text h1 css={{ fontSize: "$6xl", mb: 0, "@xsMax": { fontSize: "$3xl" }, "@smMax": { marginTop: "$5" } }}>
                          {quote.name}
                        </Text>
                        <Text h3 css={{ mb: 0, "@xsMax": { fontSize: "$1xl" }, whiteSpace: "pre-wrap" }}>
                          {quote.title}
                        </Text>
                        <Text h3 weight="normal" css={{ opacity: 0.6, "@xsMax": { fontSize: "$1xl" } }}>
                          {quote.location}
                        </Text>
                      </div>

                    </Grid>
                  </Grid.Container>
                ))}
              </Carousel>
            </Container>
          </Container>
        </div>
        {!isSmall && <Spacer y={4} />}

        <Container lg
          id="howItWorks"
          css={{ borderRadius: 15, height: "fit-content", "@mdMax": { overflow: "auto", height: "auto" } }}>
          <Spacer y={1} />
          <Text h1 css={{ fontSize: 80, "@smMax": { fontSize: 42 } }}>
            How it works:
          </Text>
          <Spacer y={1} />
          <Grid.Container>
            {HowItWorks.map((section, idx) =>
              <Grid md={4} sm={4}>
                <div style={{ padding: 15, border: "1px solid #000", margin: 10 }}>
                  <Text h2 weight="medium" css={{ background: section.color, p: "$5", lineHeight: 1, borderRadius: 5, width: 50, height: 50, textAlign: "center" }}>
                    {idx + 1}
                  </Text>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Text h2 weight={"bold"}>
                      {section.title}.
                    </Text>
                  </div>
                  <Text>
                    {section.description}
                  </Text>
                  <img
                    style={{ objectFit: "cover" }}
                    src={section.image} />
                </div>
              </Grid>)}
          </Grid.Container>

        </Container>
        {!isXSmall && <Spacer y={5} />}
      </Container>
      {isXSmall && <Spacer y={5} />}
      <Container
        id="countryFacts"
        fluid
        css={{ padding: 80, background: "#fffacc", overflow: "hidden", "@smMax": { padding: 30 } }} justify="center" display="flex" alignItems="center" direction="column">
        <Text h1 css={{ fontSize: 80, "@smMax": { fontSize: 42 }, textAlign: "center" }}>
          Nurses like you are in demand!
        </Text>
        <Text css={{ fontSize: 24, textAlign: "center", width: "60%", "@smMax": { fontSize: 18, width: "100%" } }}>
          Nursing shortages globally mean you have many potential options - but when your career and future is at stake you must make the right choice. Click on a country to learn more.
        </Text>
        <Spacer y={2} />
        <Grid.Container gap={5} alignItems="center">
          <Grid.Container gap={2} justify="center">
            {CountryStats.map(country =>
              <Grid lg={3} md={4} sm={6} xs={12}><Card variant="flat" css={{ bgColor: selectedCountry === country ? "#fff04b" : "#fff497", mb: "$5" }}>
                <Card.Body css={{ display: "flex", flexDirection: "row", justifyContent: "center", cursor: "pointer" }} onClick={() => {
                  setSelectedCountry(country)
                }}>
                  <Text h2 css={{ fontSize: 32, mb: 0, lineHeight: 1, "@smMax": { fontSize: 24 }, whiteSpace: "nowrap" }}>
                    {countryEmojiMap?.[country.name]} {country.name}
                  </Text>
                </Card.Body>
              </Card>
              </Grid>
            )}
          </Grid.Container>
          {selectedCountry && <div ref={countryInfoRef} style={{ width: "100%" }}><CountryStatistics country={selectedCountry} /></div>}
        </Grid.Container>
        <Spacer y={2} />
        <Button
          auto
          rounded
          css={{ zIndex: 1 }}
          size={"xl"}
          iconRight={<BsArrowRight />}
          onClick={onClickCta}>
          Get started for free
        </Button>
      </Container>

      <Container lg css={{ overflow: "hidden", w: "100%" }} id="faq">
        <Spacer y={6} />

        <Text h1 css={{ fontSize: 80, textAlign: "center", "@smMax": { fontSize: 42 } }}>
          Frequently asked questions
        </Text>
        <Spacer y={isXSmall ? 3 : 4} />
        <Collapse.Group>
          {Faq.map(({ question, answer }, idx) => (
            <Collapse title={question} key={`qa-${idx}`}>
              <Text css={{ fontSize: 20, opacity: 0.8, whiteSpace: "pre-line", "@smMax": { fontSize: 18 } }}>
                {answer}
              </Text>

            </Collapse>
          ))}
        </Collapse.Group>
      </Container>
      <Spacer y={4} />
      <LandingFooter />
    </>
  )
}

export default LandingPage;