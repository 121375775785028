import { Input, Spacer } from "@nextui-org/react";
import { useFormikContext } from "formik";
import { DEFAULT_INPUT_PROPS } from "types/forms";

const NurseDetailsForm = () => {
  const { handleChange, values, touched, errors } = useFormikContext<any>();

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Input
          {...DEFAULT_INPUT_PROPS}
          id="fname"
          name="fname"
          label="First Name *"
          onChange={handleChange}
          value={values.fname}
          helperText={touched?.fname && errors?.fname ? errors.fname : ""}
          helperColor={"error"} />
        <Spacer x={0.5} />
        <Input
          {...DEFAULT_INPUT_PROPS}
          id="lname"
          name="lname"
          label="Surname *"
          onChange={handleChange}
          value={values.lname}
          helperText={touched?.lname && errors?.lname ? errors.lname : ""}
          helperColor={"error"} />
      </div>
      <Spacer y={1} />
      <Input
        {...DEFAULT_INPUT_PROPS}
        id="email"
        name="email"
        label="Email *"
        onChange={handleChange}
        value={values.email}
        helperText={touched?.email && errors?.email ? errors.email : ""}
        helperColor={"error"} />
      <Spacer y={1} />
      <Input
        {...DEFAULT_INPUT_PROPS}
        id="phone"
        name="phone"
        label="Contact Number *"
        onChange={handleChange}
        value={values.phone}
        helperText={touched?.phone && errors?.phone ? errors.phone : ""}
        helperColor={"error"} />
      <Spacer y={1} />


      <Input
        {...DEFAULT_INPUT_PROPS}
        id="nationality"
        name="nationality"
        label="What's your nationality? *"
        onChange={handleChange}
        value={values.nationality}
        helperText={touched?.nationality && errors?.nationality ? errors.nationality : ""}
        helperColor={"error"} />
      <Spacer y={1} />

      <Input
        {...DEFAULT_INPUT_PROPS}
        id="currentCity"
        name="currentCity"
        label="Which city/state are you based in now? *"
        onChange={handleChange}
        value={values.currentCity}
        helperText={touched?.currentCity && errors?.currentCity ? errors.currentCity : ""}
        helperColor={"error"} />
    </div>
  )

}

export default NurseDetailsForm;