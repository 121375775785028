import { RiFeedbackLine } from "react-icons/ri"

const Benefits = [
  "Pay & Promotion",
  "Doubt solving",
  "Workplace culture",
  "Interview prep"
]

const Logos = [
  "https://www.rcn.org.uk/-/media/Royal-College-Of-Nursing/Images/Sitecore-Thumbnails/Site-Assets/Logo-2021.svg?h=106&w=270&la=en&hash=01CC92BAC84F1A98AE71DF3023E73D48",
  "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/NSWHealth-logo.png?alt=media&token=951a2c25-8478-40e8-bdca-a4f61dcea9e5",
  "https://logodownload.org/wp-content/uploads/2021/04/nhs-logo-1.png",
  "https://www.sumnercollege.edu/wp-content/uploads/2020/05/AACN.png",
  "https://www.healthcarenz.co.nz/wp-content/uploads/2021/02/cropped-Logo-vertical-.png",
  "https://albertanursing.ca/wp-content/uploads/2022/11/CNA-logo-2021_e.png",
]

const Faq = [
  {
      "question": "Why should I use Candid?",
      "answer": "Candid gives you exclusive and direct access to decision makers in your preferred destination country/countries, helping you make the right choice about where and when you apply. Speaking with Candid experts will give you deep behind-the-scenes insight you don’t get from a recruitment agency or during an interview."
  },
  {
      "question": "How does the call take place?",
      "answer": "The call will take place over video call and will be recorded and shared with you to review."
  },
  {
      "question": "If I don’t want to speak over video call but prefer to speak over voice call, is that OK?",
      "answer": "Yes, you can choose to dial in over phone if you prefer; both video and voice call dial-in details will be provided."
  },
  {
      "question": "If either myself or the expert become unavailable, can I rebook?",
      "answer": "Yes, you can rebook at no extra cost, provided you let us know within 24 hours of the call taking place."
  },
  {
      "question": "How am I billed?",
      "answer": "You pay upfront via our secure Stripe connection. If for any reason your call with a nursing expert does not take place, you will receive a full refund."
  },
  {
      "question": "How long is each call, and how will it be structured?",
      "answer": "Each call will last for 15 mins and it’s upto you what you speak about. When booking the call you will be given the opportunity to share your query with experts in advance so they can prepare too.  You should aim to focus on 1-2 topics maximum per call so you can get in-depth answers. Make sure to allow time at the start to build rapport as this enables a free-flowing conversation."
  },
  {
      "question": "Can I book an additional call or repeat calls with an expert?",
      "answer": "Yes, you can book one at a time as many times as you like with multiple experts across multiple destination countries or buy a 3 or 5 call bundle."
  }
]



const HowItWorks = [
  {
    color: "#42ffb0",
    icon: <RiFeedbackLine />,
    title: "choose your country and specialism",
    description: "Get tailored advice based on preferred destination(s) and nursing specialism(s)",
    image: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Step1_Landing.png?alt=media&token=d10d5ecc-1aec-467c-9529-a3da980fd777"
  },
  {
    color: "#ff82d0",
    icon: <RiFeedbackLine />,
    title: "get matched to an expert",
    description: "All our experts are registered & qualified local market nursing practitioners and decision makers who can help you make informed choices",
    image: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Landing_Step2.png?alt=media&token=56cac4ee-c55a-4bc1-b969-80a9fa20f495"
  },
  {
    color: "#ff994e",
    icon: <RiFeedbackLine />,
    title: "ask them anything",
    description: "There’s no such thing as a bad question. Get candid advice from people that have been there and done it.",
    image: "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/Landing_Step3.png?alt=media&token=ec2e7a82-9fc4-45b3-906a-9f9ab3f70bca"
  },
]

const Quotes = [
  {
    image: "https://ik.imagekit.io/jaiten/candid/o/Nurse_Amy.png?alt=media&token=f4bc7fdd-f0d7-4e8b-9e79-73a5b26dd9a8",
    name: "Ask Amy",
    title: "Nurse Manager\nPaediatrics",
    location: "Dublin, Ireland",
    question: "Should I specialise or be a generalist?",
    color: "#ff82d0",
  },
  {
    image: "https://ik.imagekit.io/jaiten/candid/o/Nurse_Anna.png?alt=media&token=d18c23f4-40a1-417d-832c-f2a14febebab",
    name: "Ask Anna",
    title: "Nurse Matron\nMental Health",
    location: "London, England",
    question: "Are international nurses treated equally in your country?",
    color: "#5ad2fd"
  },
  {
    image: "https://ik.imagekit.io/jaiten/candid/o/ClinicalNurse.png?alt=media&token=af646dbc-4cbb-443f-86ac-4818822247b3",
    name: "Ask Brian",
    title: "Clinical Nurse\nCommunity Nursing",
    location: "Boston, USA",
    question: "How much importance is given to communication style?",
    color: "#43ffb0",
  },
  {
    image: "https://ik.imagekit.io/jaiten/candid/o/OncologyNurse.png?alt=media&token=1458b83f-59f2-4bd0-8624-80500bcbcf51",
    name: "Ask Stefanie",
    title: "Nursing Director\nOncology",
    location: "Central Singapore",
    question: "How much detail am I expected to know?",
    color: "#f2ef02"
  },
  {
    image: "https://ik.imagekit.io/jaiten/candid/o/Nurse_Barbara.png?alt=media&token=da8706d7-64e3-4ea5-928b-0127ae05178d",
    name: "Ask Barbara",
    title: "Nurse Midwife\nEmergency",
    location: "Newcastle, England",
    question: "Should I choose a public or private healthcare setting?",
    color: "#dc74dc",
  },
  {
    image: "https://ik.imagekit.io/jaiten/candid/o/NurseArshdeep.png?alt=media&token=c3b26be8-ee65-45ba-9d77-9e19b07cde73",
    name: "Ask Arshdeep",
    title: "Registered Nurse\nIntensive Care Unit",
    location: "Melbourne, Australia",
    question: "How safe is your city for women?",
    color: "#ff994e",
  },
]

interface CountryStat {
  icon?: JSX.Element;
  stat: string;
  description: string;
}

interface MaternityPaternityLeave {
  maternity_leave: string;
  paternity_leave?: string;
}

export interface CountryNursingData {
  name: string;
  image: string;
  working_population: string;
  nurses: string;
  nurses_per_capita: string;
  qualification: string;
  language_certification: string;
  holidays: string;
  maternity_paternity: MaternityPaternityLeave;
  currency: string;
  minimum_wage: string;
  taxes: string;
  tax_free_allowance: string;
  fun_fact: string;
}


const CountryStats: CountryNursingData[] = [
  {
    "name": "Australia",
    "image": "https://static.vecteezy.com/system/resources/previews/011/571/471/original/circle-flag-of-australia-free-png.png",
    "working_population": "~13 million",
    "nurses": "~350,000",
    "nurses_per_capita": "12.7 per 1,000",
    "qualification": "AHPRA registration, degree",
    "language_certification": "IELTS or OET",
    "holidays": "20 days",
    "maternity_paternity": {
      "maternity_leave": "18 weeks paid leave",
      "paternity_leave": "2 weeks paid leave"
    },
    "currency": "Australian Dollar (AUD)",
    "minimum_wage": "$23.23 AUD per hour",
    "taxes": "Progressive tax rate, 19-45%",
    "tax_free_allowance": "$18,200 AUD",
    "fun_fact": "Australia’s nurses travel over 7.1 million kilometers annually with the Royal Flying Doctor Service."
  },
  {
    "name": "Canada",
    "image": "https://firebasestorage.googleapis.com/v0/b/candid-662da.appspot.com/o/landing%2Fnew_landing%2FCanada-Logo.png?alt=media&token=7fdc7962-5acb-49b7-b761-afb98a2293f1",
    "working_population": "~20 million",
    "nurses": "~440,000",
    "nurses_per_capita": "10.4 per 1,000",
    "qualification": "Provincial licensure, NCLEX-RN",
    "language_certification": "IELTS or CELBAN",
    "holidays": "~20 days",
    "maternity_paternity": {
      "maternity_leave": "15-18 weeks paid at 55% of average earnings",
      "paternity_leave": "5-8 weeks paid"
    },
    "currency": "Canadian Dollar (CAD)",
    "minimum_wage": "$15.00 CAD per hour (varies by province)",
    "taxes": "Progressive tax rate, 15-33%",
    "tax_free_allowance": "$13,229 CAD",
    "fun_fact": "Canada was the first country to regulate nursing as a profession, starting in Nova Scotia in 1910."
  },
  {
    "name": "India",
    "image": "https://taitongah.com.sg/wp-content/uploads/2020/11/india-flag-round-icon-256.png",
    "working_population": "~500 million",
    "nurses": "~3.07 million",
    "nurses_per_capita": "1.7 per 1,000",
    "qualification": "INC registration, degree",
    "language_certification": "Not specifically required",
    "holidays": "12 days",
    "maternity_paternity": {
      "maternity_leave": "26 weeks paid leave",
      "paternity_leave": "15 days paid leave"
    },
    "currency": "Indian Rupee (INR)",
    "minimum_wage": "₹240 per day",
    "taxes": "Progressive tax rate, 5-30%",
    "tax_free_allowance": "₹250,000",
    "fun_fact": "Indian nurses have played a critical role in the country's success in managing large-scale vaccination campaigns, particularly in rural areas."
  },
  {
    "name": "Ireland",
    "image": "https://vectorflags.s3.amazonaws.com/flags/ie-circle-01.png",
    "working_population": "~2.5 million",
    "nurses": "~70,000",
    "nurses_per_capita": "12.2 per 1,000",
    "qualification": "NMBI registration, degree",
    "language_certification": "IELTS or OET",
    "holidays": "20 days",
    "maternity_paternity": {
      "maternity_leave": "26 weeks paid leave",
      "paternity_leave": "2 weeks paid leave"
    },
    "currency": "Euro (EUR)",
    "minimum_wage": "€11.30 per hour",
    "taxes": "Progressive tax rate, 20-40%",
    "tax_free_allowance": "€16,500",
    "fun_fact": "Ireland is home to one of the oldest nursing unions, the Irish Nurses and Midwives Organisation (INMO)."
  },
  {
    "name": "New Zealand",
    "image": "https://static.vecteezy.com/system/resources/previews/011/571/359/original/circle-flag-of-new-zealand-free-png.png",
    "working_population": "~2.5 million",
    "nurses": "~55,000",
    "nurses_per_capita": "10.2 per 1,000",
    "qualification": "NCNZ registration, degree",
    "language_certification": "IELTS or OET",
    "holidays": "20 days",
    "maternity_paternity": {
      "maternity_leave": "26 weeks paid leave",
      "paternity_leave": "2 weeks paid leave"
    },
    "currency": "New Zealand Dollar (NZD)",
    "minimum_wage": "$22.70 NZD per hour",
    "taxes": "Progressive tax rate, 10.5-39%",
    "tax_free_allowance": "$14,000 NZD",
    "fun_fact": "New Zealand has a unique nursing role called Nurse Practitioners who can prescribe medications independently."
  },
  {
    "name": "Philippines",
    "image": "https://cdn.countryflags.com/thumbs/philippines/flag-round-250.png",
    "working_population": "~45 million",
    "nurses": "~500,000",
    "nurses_per_capita": "5.4 per 1,000",
    "qualification": "PRC licensure, degree",
    "language_certification": "English proficiency tests",
    "holidays": "15 days",
    "maternity_paternity": {
      "maternity_leave": "105 days paid leave",
      "paternity_leave": "7 days paid leave"
    },
    "currency": "Philippine Peso (PHP)",
    "minimum_wage": "₱570 per day",
    "taxes": "Progressive tax rate, 20-35%",
    "tax_free_allowance": "₱250,000",
    "fun_fact": "The Philippines is one of the largest exporters of nurses worldwide, with around 25% of all overseas nurses being Filipinos."
  },
  {
    "name": "Singapore",
    "image": "https://vectorflags.s3.amazonaws.com/flags/sg-circle-01.png",
    "working_population": "~5.6 million",
    "nurses": "~40,000",
    "nurses_per_capita": "7.1 per 1,000",
    "qualification": "SNB registration, degree",
    "language_certification": "IELTS or TOEFL",
    "holidays": "14 days",
    "maternity_paternity": {
      "maternity_leave": "16 weeks paid leave.",
      "paternity_leave": "2 weeks paid leave."
    },
    "currency": "Singapore Dollar (SGD)",
    "minimum_wage": "S$1,600 per month",
    "taxes": "Progressive tax rate, 2-22%",
    "tax_free_allowance": "S$20,000",
    "fun_fact": "Singapore’s healthcare system is ranked among the best in the world, with nurses playing a crucial role in maintaining high standards."
  },
  {
    "name": "South Africa",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/South-africa_flag_icon_round.svg/2048px-South-africa_flag_icon_round.svg.png",
    "working_population": "~20 million",
    "nurses": "~80,000",
    "nurses_per_capita": "1.5 per 1,000",
    "qualification": "SANC registration, degree",
    "language_certification": "IELTS or OET",
    "holidays": "15 days",
    "maternity_paternity": {
      "maternity_leave": "4 months paid leave",
      "paternity_leave": "10 days paid leave"
    },
    "currency": "South African Rand (ZAR)",
    "minimum_wage": "R23.19 per hour",
    "taxes": "Progressive tax rate, 18-45%",
    "tax_free_allowance": "R79,000",
    "fun_fact": "South Africa has one of the most advanced nursing training systems in Africa, but faces challenges in retaining skilled nurses due to emigration."
  },
  {
    "name": "South Korea",
    "image": "https://cdn.countryflags.com/thumbs/nigeria/flag-round-250.png",
    "working_population": "~51 million",
    "nurses": "~380,000",
    "nurses_per_capita": "7.3 per 1,000",
    "qualification": "KNC registration, degree",
    "language_certification": "TOPIK (for foreign nurses)",
    "holidays": "15 days",
    "maternity_paternity": {
      "maternity_leave": "90 days paid leave",
      "paternity_leave": "10 days paid leave"
    },
    "currency": "South Korean Won (KRW)",
    "minimum_wage": "₩9,620 per hour",
    "taxes": "Progressive tax rate, 6-45%",
    "tax_free_allowance": "₩15,000,000",
    "fun_fact": "South Korea has one of the highest ratios of nurses to doctors, making collaboration key in healthcare settings."
  },
  {
    "name": "United Arab Emirates",
    "image": "https://cdn-icons-png.flaticon.com/512/323/323301.png",
    "working_population": "~7.5 million",
    "nurses": "~45,000",
    "nurses_per_capita": "5.8 per 1,000",
    "qualification": "DHA/MOH/HAAD registration",
    "language_certification": "IELTS or OET",
    "holidays": "30 days",
    "maternity_paternity": {
      "maternity_leave": "45 days paid leave. No statutory paternity leave"
    },
    "currency": "UAE Dirham (AED)",
    "minimum_wage": "No minimum wage",
    "taxes": "No income tax",
    "tax_free_allowance": "Not applicable",
    "fun_fact": "Nurses in the UAE represent over 70 different nationalities, making it one of the most diverse nursing workforces in the world."
  },
  {
    "name": "United Kingdom",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/1024px-United-kingdom_flag_icon_round.svg.png",
    "working_population": "~33 million",
    "nurses": "~700,000",
    "nurses_per_capita": "8.0 per 1,000",
    "qualification": "NMC registration, degree",
    "language_certification": "IELTS or OET",
    "holidays": "28 days",
    "maternity_paternity": {
      "maternity_leave": "First 6 weeks at 90% of average earnings, then £156.66 or 90% (whichever is lower) for 33 weeks",
      "paternity_leave": "2 weeks paid at the statutory rate"
    },
    "currency": "Pound Sterling (GBP)",
    "minimum_wage": "£10.42 per hour",
    "taxes": "Progressive tax rate, 20-45%",
    "tax_free_allowance": "£12,570",
    "fun_fact": "Florence Nightingale, the founder of modern nursing, was British."
  },
  {
    "name": "United States",
    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/1024px-United-kingdom_flag_icon_round.svg.png",
    "working_population": "~160 million",
    "nurses": "~4.3 million",
    "nurses_per_capita": "12.3 per 1,000",
    "qualification": "State board licensure, NCLEX-RN",
    "language_certification": "English proficiency generally required",
    "holidays": "Varies by state, ~10 days federal",
    "maternity_paternity": {
      "maternity_leave": "No federal mandate; FMLA provides up to 12 weeks unpaid leave for both maternity and paternity"
    },
    "currency": "US Dollar (USD)",
    "minimum_wage": "$7.25 per hour (varies by state)",
    "taxes": "Progressive tax rate, 10-37%",
    "tax_free_allowance": "Varies by state and federal levels",
    "fun_fact": "Nurses in the US can specialize in over 100 areas, including space nursing!"
  }
];

const Statistics = [
  {
    stat: "12%",
    description: "Increase in Nursing Demand by 2030"
  },
  {
    stat: "6M+",
    description: "Global Nurse Shortage"
  },
  {
    stat: "$77.6K",
    description: "Median Annual Salary in the US"
  },
  {
    stat: "9%",
    description: "Projected Job Growth in the US"
  }
]
export {
  Statistics,
  CountryStats,
  HowItWorks,
  Faq,
  Logos,
  Benefits,
  Quotes
}